*{
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}

.popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  z-index: 9999;
  font-family: 'HurmeGeometricSans1', sans-serif !important;
  width: 90%;
  max-width: 400px;
}

.popup-content {
  position: relative;
  font-family: 'HurmeGeometricSans1', sans-serif !important;
}

.form-control:focus {
  border-color: #d2ab67 !important;
  outline: none !important;
  box-shadow: none !important;
  font-family: 'HurmeGeometricSans1', sans-serif !important;
}

.floating-btn {
  position: fixed;
  bottom: 16px;
  left: 16px;
  background: #d2ab67;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 50px;
  cursor: pointer;
  font-family: 'HurmeGeometricSans1', sans-serif !important;
}

.floating-btn:hover {
  background: #d2ab67 !important;
  font-family: 'HurmeGeometricSans1', sans-serif !important;
}

.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'HurmeGeometricSans1', sans-serif !important;
}

/* .popup-content {
  background: white;
  padding: 20px;
  border-radius: 10px;
  width: 300px;
  font-family: 'HurmeGeometricSans1', sans-serif !important;
  text-align: center;
} */


.popup-content {
  background: white;
  padding: 11px;
  border-radius: 10px;
  width: 300px;
  margin-left: 27px;
  font-family: 'HurmeGeometricSans1', sans-serif !important;
  text-align: center;
}

.close-btn {
  position: absolute;
  top: 2px;
  right: 2px;
  background: transparent;
  border: none;
  font-size: 20px;
  border-radius: 10px;
  cursor: pointer;
  font-family: 'HurmeGeometricSans1', sans-serif !important;
}

.close-btn:hover {
  background: whitesmoke;
  color: black !important;
}

/* Responsive adjustments for mobile devices */
@media screen and (max-width: 768px) {
  .popup {
      width: 100%;
      padding: 10px;
      margin-left: 9px;
      max-width: 90%;
  }

  .popup-content {
      width: 95%;
      margin-left:8px;
  }

  .floating-btn {
      bottom: 23px;
      left: 19%;
      transform: translateX(-50%);
      font-size: 12px;
      padding: 8px 16px;
  }

  .close-btn {
      font-size: 18px;
      top: 5px;
      right: 5px;
  }
}
