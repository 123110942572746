.item-display {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-right: auto;
}

.slick-prev:before{
    content: '←' !important;
    font-size: 20px !important;
    background: #d2ab67 !important;
    border-radius: 50% !important;
    border: 2px solid #d2ab66 !important;
    opacity: 1 !important;
}

.slick-next:before{
    content: '→' !important;
    font-size: 20px !important;
    background: #d2ab67 !important;
    border-radius: 50% !important;
    border: 2px solid #d2ab66 !important;
    opacity: 1 !important;
}